










































































import { Component, Ref, Vue } from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import {
  CapitalIncomeRecordFromOtherDetailDto,
  CapitalIncomeRecordFromOtherDto,
  AttachmentHostType,
  AuditFlowScope,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
@Component({
  name: "CapitalIncomeRecordFromOtherDetail",
  components: {
    AttachmentsView,
    ExportWord,
    AuditBlock,
  },
})
export default class CapitalIncomeRecordFromOtherDetail extends Vue {

  detailId?: number;
  detail: CapitalIncomeRecordFromOtherDto = {};
  selector = "table-detail";
  filename = "其他资金收入";
  styles = "";

  get hostTypeFile() {
    return AttachmentHostType.Internal_Project_Monthly_Summarize_Attachment;
  }

  created() {
    if (this.$route.params.id) {
      this.detailId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }
  // 获取详情
  async fetchDetail() {
    await api.capitalIncomeRecordFromOther
      .get({ id: this.detailId })
      .then((res) => {
        this.detail = { ...res };
      });
  }

  get auditApi(){
    return {
      canAudit:api.capitalIncomeRecordFromOther.canAudit,
      userAudit: api.capitalIncomeRecordFromOther.audit} as AuditApi
  }


  // 返回
  private cancel() {
    this.$router.back();
  }
}
