



































































































































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";
import ImportExcel from "@/components/ImportExcel/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import {
  CapitalIncomeRecordFromOtherDto,
  CapitalIncomeRecordFromOtherDtoPagedResultDto,
  PayType,
  AuditFlowScope,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import EditLink from "@/views/project/otherIncome/edit.vue";
// import IncomeDetail from "@/views/project/otherIncome/detail.vue";
import { getIncomeCategory } from "@/utils/enumToSelectItems";
import AbSelect from "@/components/AbSelect/index.vue";

@Component({
  name: "OtherIncomeList",
  components: {
    EditLink,
    PagedTableView,
    //IncomeDetail,
    ImportExcel,
    ExportButton,
    AgileAuditTag,
    AbSelect,
  },
})
export default class OtherIncomeList extends Vue {
  queryForm = {
    bankAccount: "",
    incomeDateStart: "",
    incomeDateEnd: "",
  };

  editLinkId = 0;
  private currentDetail: CapitalIncomeRecordFromOtherDto = {};

  showTotalMoney = 1;
  totalMoney = 0;

  incomeCategoryList = getIncomeCategory();

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    this.getTotalMoney();
    return api.capitalIncomeRecordFromOther.getAll(params);
  }

  // 合计金额
  getTotalMoney() {
    api.capitalIncomeRecordFromOther
      .getOtherTotalMoney(this.queryForm)
      .then((res) => {
        this.totalMoney = res;
      });
  }

  // 操作按钮
  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  // 新建
  handleCreate() {
    this.editLinkId = 0;
    (this.$refs.editForm as any).show = true;
  }

  // 编辑
  handleEdit(index: number, row: CapitalIncomeRecordFromOtherDto) {
    this.editLinkId = row.id!;
    (this.$refs.editForm as any).show = true;
    (this.$refs.editForm as any).form = row;

    console.warn("%cu must reWrite this method", "color:#0048BA;");
  }

  handleViewDetail(row: CapitalIncomeRecordFromOtherDto) {
    this.currentDetail = row;
    (this.$refs.incomeDetail as any).show = true;
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "otherIncomeDetail",
      params: {
        id: row.id,
      },
    });
  }

  // 删除
  async handleDelete(index: number, row: CapitalIncomeRecordFromOtherDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.capitalIncomeRecordFromOther
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  async handleStartAudit(index: number, row: CapitalIncomeRecordFromOtherDto) {
    // await api.capitalIncomeRecordFromOther
    //   .startAudit({ body: { id: row.id } })
    //   .then(res => {
    //     this.$message.success("已提交");
    //   });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.CapitalIncomeRecordFromOther,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  handelOnSaved() {
    this.fetchData(this.queryForm);
  }
}
