








































































































































































import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import {
  CapitalIncomeRecordFromOtherDto,
  CapitalIncomeRecordFromOtherCreateOrUpdateDto,
  CapitalIncomeRecordFromOtherDetailCreateOrUpdateDto,
  CapitalProperty,
  IncomeCategory,
  CurrencyType,
  DataDictionaryDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import validate from "@/utils/validate";
import {
  getCapitalPropertyList,
  getCurrencyType,
  getIncomeCategory,
  getPayTypes,
} from "@/utils/enumToSelectItems";

@Component({
  name: "EditCapitalIncomeRecordFromOther",
  filters: {},
})
export default class EditCapitalIncomeRecordFromOther extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  otherDetailListDefaultData: CapitalIncomeRecordFromOtherDetailCreateOrUpdateDto[] =
    [];
  defaultData: CapitalIncomeRecordFromOtherCreateOrUpdateDto = {
    incomeDate: undefined,
    incomeType: undefined,
    payBankAccount: undefined,
    bankAccount: undefined,
    totalIncomeMoney: 0,
    auditFlowId: undefined,
    details: this.otherDetailListDefaultData,
    id: 0,
  };
  activeName = "first"; //显示第一个选项卡

  show = false;
  form: CapitalIncomeRecordFromOtherCreateOrUpdateDto = { ...this.defaultData };
  payType = getPayTypes();
  capitalPropertyList = getCapitalPropertyList();
  incomeCategoryList = getIncomeCategory();

  currencyTypeList = getCurrencyType();
  payBankAccountList: DataDictionaryDto[] = [];

  get title() {
    if (this.dataId) {
      return "编辑";
    } else {
      return "新建";
    }
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });
      this.activeName = "first"; //显示第一个选项卡

      if (this.dataId) {
        await api.capitalIncomeRecordFromOther
          .get({ id: this.dataId })
          .then((res: CapitalIncomeRecordFromOtherDto) => {
            this.form = res!;
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 200);
          });
      } else {
        this.form = { ...this.defaultData };
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  created() {
    this.initDefaultData();
  }

  initDefaultData() {
    //付款账号
    api.dataDictionary
      .getDataDictionaryListByKey({ key: "PayBankAccount" })
      .then((res) => {
        this.payBankAccountList = res.items!;
      });
  }

  async save() {
    console.log(this.form);
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.capitalIncomeRecordFromOther.update({
            body: { ...this.form },
          });
        } else {
          await api.capitalIncomeRecordFromOther.create({
            body: { ...this.form },
          });
        }
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("onSave");
      }
    });
  }

  cancel() {
    this.show = false;
  }

  // 添加一行
  private addRow(r: number) {
    var obj = {
      id: undefined,

      sequence: undefined,

      capitalProperty: CapitalProperty.NonFinite,

      /**  */
      incomeCategory: IncomeCategory.Interest,

      /**  */
      currencyType: CurrencyType.RMB,

      /** 金额 */
      money: 0,

      /** 摘要 */
      remark: undefined,
    };
    this.form.details = [...this.form.details!, obj];
  }

  // 删除整行
  private deleteRow(index: number) {
    this.form.details!.splice(index, 1);
  }

  linkRule = {
    incomeDate: [
      {
        required: true,
        message: "请输入收入日期",
        trigger: "blur",
      },
    ],
    bankAccount: [
      {
        required: true,
        message: "请输入账号",
        trigger: "blur",
      },
      /*{
                    type: "number",
                    message: "账号必须为数字",
                    trigger: "blur"
                }*/
    ],
    incomeType: [
      {
        required: true,
        message: "请输入收入方式",
        trigger: "blur",
      },
    ],
    displayOrder: [
      {
        required: true,
        message: "请输入显示顺序",
        trigger: "change",
      },
      {
        type: "number",
        message: "显示顺序必须为数字",
        trigger: "change",
      },
    ],
  };

  detailRule = {
    money: [
      {
        required: true,
        trigger: "change",
        validator: validate.validMoney,
      },
    ],
  };
}
